import { graphql } from "gatsby"
import React, { ChangeEvent, useContext, useEffect } from "react"
import { IoIosArrowUp } from "react-icons/io"
import { MdLocationDisabled, MdLocationSearching } from "react-icons/md"
import { PageContext } from "../../../context/PageContext"
import useDatoMicrocopy from "../../../hooks/useDatoMicrocopy"
import useDealers, { HarviaDealer } from "../../../hooks/useDealers"
import useGeolocation from "../../../hooks/useGeolocation"
import { isBrowser, isString } from "../../../utils"
import Button from "../../atoms/Button"
import LoadingSpinner from "../../atoms/LoadingSpinner"
import SubHeading from "../../atoms/SubHeading"
import DealerMap from "../../molecules/DealerMap/DealerMap"
import DatoContentModules from "../DatoContentModules"
import DatoDealerCard from "../DatoDealerCard"
import * as styles from "./DatoDealerFinder.module.scss"

function isServiceAreaCollection(val: unknown): val is Queries.DatoServiceAreaCollectionFragment {
  return val !== null
}
function isServiceArea(val: unknown): val is Queries.DatoServiceAreaFragment {
  return val !== null
}

function sortByTitle<T extends { title: string | null }>(val: Readonly<T[]>): T[] {
  return [...val].sort((a, b) => {
    if (!a.title || !b.title) {
      return 0
    }
    return a.title.localeCompare(b.title)
  })
}

const DatoDealerFinder = (props: Queries.DatoDealerFinderFragment) => {
  //console.log('Rendering DatoDealerFinder', props)
  const { locale } = useContext(PageContext)
  const t = useDatoMicrocopy("contact", locale)
  // all dealers

  const serviceAreaCollections = sortByTitle(props.serviceAreaCollections?.filter(isServiceAreaCollection) || [])

  const areaIds: string[] =
    serviceAreaCollections
      ?.map(areas => areas?.serviceAreas?.map(area => area?.id))
      .flat()
      .filter(isString) || []

  const dealers = useDealers(areaIds)

  const [areaCollectionId, setAreaCollectionId] = React.useState<string>(
    serviceAreaCollections?.find(c => c !== undefined || c !== null)?.id || ""
  )
  /** User selected area of interest. Selection presented as dropdown in UI */
  const [areaId, setAreaId] = React.useState<string>()
  /** User selected contact type. Selection presented as dropdown in UI  */
  const [selectedContactType, setSelectedContactType] = React.useState<string>()
  /** Browser geolocation  */
  const [locationState, getLocation] = useGeolocation()

  const [isScrolled, setIsScrolled] = React.useState<boolean>()
  const [results, setResults] = React.useState<Readonly<HarviaDealer[]>>(dealers)
  const container = React.useRef(null)

  const defaultContactPriority = 50

  //const { locale } = React.useContext(PageContext)

  function locateNearestDealers() {
    getLocation({
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    })
  }

  useEffect(() => {
    // console.debug("locationState changed", locationState)
    const { error, loading, longitude, latitude } = locationState
    if (loading) {
      // still querying users device, nothing to do
      return
    }
    if (error) {
      if (error !== true) {
        // error in browser gelocation api
        console.warn(`Geolocation ERROR(${error.code}): ${error.message}`)
      } else {
        console.warn(`Geolocation ERROR`)
      }
      return
    }
    if (longitude !== null && latitude !== null) {
      showResultsByGeolocation(latitude, longitude)
    }
  }, [locationState])

  function showResultsByGeolocation(latitude: number, longitude: number) {
    const nearest: { dist: number; dealer: HarviaDealer }[] = []
    let maxdist = Number.POSITIVE_INFINITY
    const returnCount = 4

    const dealerSet = results?.length ? results : dealers // haetaanko nykyiseltä alueelta, vai kaikista dealereista

    // Lasketaan suurpiirteinen (mutta nopea) etäisyys kaikkiin jälleenmyyjiin.
    dealerSet.forEach(dealer => {
      if (!(dealer.coordinates?.latitude && dealer.coordinates?.longitude)) {
        return
      }
      const dlat = Math.abs(dealer.coordinates.latitude - latitude)

      if (dlat > maxdist) return

      // lisätään pieni kerroin mittaan, niin tulee pyöreämpi lopputulos pohjoisilla leveyspiireillä
      const dlon = Math.abs(dealer.coordinates.longitude - longitude) * 1.3

      if (dlon > maxdist) return

      const dist = dlat * dlat + dlon * dlon

      if (dist > maxdist) return

      nearest.push({ dist, dealer })
      nearest.sort((a, b) => a.dist - b.dist)

      if (nearest.length > returnCount) {
        nearest.pop()
        maxdist = nearest[nearest.length - 1].dist
      }
    })

    if (nearest.length < 1) {
      return
    }
    setResults(nearest.map(itm => itm.dealer))
  }

  function areaCollectionSelectHandler(event: ChangeEvent<HTMLSelectElement>) {
    const id = event.currentTarget.value
    setAreaId("")
    setAreaCollectionId(id)

    showAllDealersFromCollection(id)
  }

  function areaSelectHandler(event: ChangeEvent<HTMLSelectElement>) {
    const id = event.currentTarget.value
    setArea(id)
  }

  // TODO: kun valitsee tyypin, päivittää listaan vain sillä tyypillä säilyttäen muut state(collection, area)
  function contactTypeSelectHandler(event: ChangeEvent<HTMLSelectElement>) {
    const id = event.currentTarget.value
    setSelectedContactType(id)
  }

  function setArea(id: string) {
    setAreaId(id)

    // TODO this should be an effect?
    const dealersFromArea = dealers.filter(itm => itm.serviceAreas?.find(area => area?.id === id))
    if (dealersFromArea.length) {
      setResults(dealersFromArea)
    } else if (id) {
      setResults([])
    } else {
      showAllDealersFromCollection(areaCollectionId)
    }
  }

  function showAllDealersFromCollection(id: string) {
    if (id) {
      // find all dealers from current collection
      const dealersFromCollection = getDealersFromCollection(id)
      setResults(dealersFromCollection)
    } else {
      // show all
      setResults(dealers)
    }
  }

  function getDealersFromCollection(id: string) {
    return dealers.filter(dealer =>
      dealer.serviceAreas?.find(area =>
        serviceAreaCollections
          ?.find(collection => collection?.id === id)
          ?.serviceAreas?.find(area2 => area2?.id === area?.id)
      )
    )
  }

  // function getDealersFromArea(id: string) {
  //   return dealers.filter(itm => itm.serviceAreas.find(area => id === area.id))
  // }

  const backToTop = () => {
    if (container.current && isBrowser) {
      window.scrollTo({ top: container?.current?.["offsetTop"] || 0 - 80 })
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", updateScroll)

    function updateScroll() {
      if (container.current && isBrowser) {
        if (window.scrollY > (container?.current?.["offsetTop"] || 0) - 80) {
          //if (!isScrolled)
          setIsScrolled(true)
        } else {
          //if (isScrolled)
          setIsScrolled(false)
        }
      }
    }

    return () => {
      window.removeEventListener("scroll", updateScroll)
    }
  }, [])

  const areaCol = serviceAreaCollections?.find(collection => collection?.id === areaCollectionId)

  const areas = sortByTitle(areaCol?.serviceAreas?.filter(isServiceArea) || [])

  const curArea = areas?.find(area => area.id === areaId)

  const resultsByAreas: Array<{ id: string | null; title: string | null; dealers: HarviaDealer[] }> = curArea
    ? [{ id: curArea.id, title: curArea.title, dealers: [] }]
    : []

  results.forEach(dealer => {
    if (!selectedContactType || selectedContactType === dealer.contactType) {
      if (curArea) {
        // listataan valitun arean alle
        resultsByAreas[0].dealers.push(dealer)
      } else {
        // Luodaan dealer kortit kaikkien alueidensa alle
        const validAreas = dealer.serviceAreas?.filter(area => areaIds.includes(area?.id || ""))

        validAreas?.forEach(area => {
          if (!area) return
          const group = resultsByAreas.find(group => group.id == area.id)
          if (!group) {
            resultsByAreas.push({ id: area.id, title: area.title, dealers: [dealer] })
          } else {
            group.dealers.push(dealer)
          }
        })
      }
    }
  })

  resultsByAreas.sort((a, b) => {
    if (!a.title || !b.title) {
      return 0
    }
    return a.title.localeCompare(b.title)
  })

  //console.debug(resultsByAreas)

  //console.debug(results)

  return (
    <div className={styles.container} ref={container}>
      <SubHeading text={props.title} />
      <div className={styles.content}>
        <div className={styles.toolSection}>
          <div className="layout-container">
            <div className={styles.toolbar}>
              <div className={styles.tool}>
                <h3>{t("dealerSelectArea", "Select area")}</h3>

                {serviceAreaCollections.length > 1 && (
                  <>
                    <select onChange={areaCollectionSelectHandler}>
                      <option value="">{props.selectCollectionLabel}</option>
                      {serviceAreaCollections.map(areaCollection => {
                        return areaCollection.id && areaCollection.title ? (
                          <option key={areaCollection.id} value={areaCollection.id}>
                            {areaCollection.title}
                          </option>
                        ) : null
                      })}
                    </select>
                    {areaCollectionId && <span> | </span>}
                  </>
                )}

                {areaCollectionId && (
                  <select onChange={areaSelectHandler} value={areaId}>
                    <option value="">{props.selectAreaLabel}</option>
                    {areas?.map(area => {
                      return area.id && area.title ? (
                        <option key={area.id} value={area.id}>
                          {area.title}
                        </option>
                      ) : null
                    })}
                  </select>
                )}
              </div>
              {/* TODO: microcopy kuntoon
               * implementoi contactTypeSelectHandler
               */}
              {/* <h3>{t("dealerSelectArea", "Select area")}</h3> */}
              <div className={styles.tool}>
                <h3>{t("dealerSelectType", "Select type")}</h3>
                <select onChange={contactTypeSelectHandler} value={selectedContactType}>
                  <option value="">{props.selectDealerTypeLabel}</option>
                  <option value="Retailer">{t("dealerTypeRetailer", "Retailer")}</option>
                  <option value="Electrical supply store">
                    {t("dealerTypeElectricalSupplyStore", "Electrical supply store")}
                  </option>
                  <option value="Service shop">{t("dealerTypeServiceShop", "Service shop")}</option>
                </select>
              </div>

              <div className={styles.tool}>
                <h3>{t("dealerShowNearestDealers", "Show nearest dealers")}</h3>
                <div style={{ position: "relative" }}>
                  <Button label={t("dealerLocateUser", "Locate")} size="flat" onClick={() => locateNearestDealers()} />
                  <span className={styles.spinner}>
                    <LoadingSpinner loading={locationState.loading} />
                  </span>
                  {!locationState.loading && (
                    <span style={{ margin: "0 1em" }}>
                      {locationState.accuracy ? (
                        <>
                          <MdLocationSearching style={{ verticalAlign: "middle" }} />{" "}
                          <span style={{ verticalAlign: "middle" }}>{Math.round(locationState.accuracy)} m</span>
                        </>
                      ) : (
                        <MdLocationDisabled style={{ verticalAlign: "middle" }} title="Location disabled" />
                      )}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* end of Toolbar */}
        <div className={styles.contentSection}>
          <div className="layout-container">
            {props.showMap && (
              <DealerMap
                dealers={results.filter(dealer => !selectedContactType || selectedContactType === dealer.contactType)}
                clusters={!curArea && areas}
                onMarkerClick={setArea}
              />
            )}

            {results?.length ? (
              <>
                {resultsByAreas.map(areaGroup => {
                  return (
                    <React.Fragment key={areaGroup.id}>
                      <h3>{areaGroup.title}</h3>
                      <div className={styles.cards}>
                        {sortByTitle(areaGroup.dealers)
                          .sort(
                            (a, b) =>
                              (a.contactPriority || defaultContactPriority) -
                              (b.contactPriority || defaultContactPriority)
                          )
                          .map(dealer => {
                            return <DatoDealerCard {...dealer} key={dealer.key} />
                          })}
                      </div>
                    </React.Fragment>
                  )
                })}
              </>
            ) : (
              props.defaultContent?.modules && (
                <div className={styles.defaultContent}>
                  <DatoContentModules modules={props.defaultContent.modules} />
                </div>
              )
            )}
          </div>
        </div>
      </div>
      {isScrolled && results.length > 5 && (
        <button className={styles.toTop} title="Scroll to top" aria-label="Scroll to top" onClick={backToTop}>
          <IoIosArrowUp />
        </button>
      )}
    </div>
  )
}

export default DatoDealerFinder

export const query = graphql`
  fragment DatoServiceArea on DatoCmsServiceArea {
    id: originalId
    title(fallbackLocales: "en")
  }
  fragment DatoServiceAreaCollection on DatoCmsServiceAreaCollection {
    id: originalId
    title(fallbackLocales: "en")
    serviceAreas {
      ...DatoServiceArea
    }
  }
  fragment DatoDealerFinder on DatoCmsDealerFinder {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    title
    serviceAreaCollections {
      ...DatoServiceAreaCollection
    }
    defaultContent {
      ...DatoSharedModularContentModules
    }
    selectCollectionLabel
    selectAreaLabel
    selectDealerTypeLabel
    showMap
  }
`
