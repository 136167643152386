import { useState } from "react"
import { Nullable } from "../types"

type GeoLocationState = Nullable<GeolocationCoordinates> & {
  /** `true` when device query process is ongoing, `false` otherwise (done and we have success or error) */
  loading: boolean
  /**
   * Either `null` if no error, `GeolocationPositionError` if device query errors out or `true` if some other error
   * See https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
   */
  error: GeolocationPositionError | true | null
}

const initState: GeoLocationState = {
  /** A `double` representing the accuracy of the latitude and longitude properties, expressed in meters. */
  accuracy: null,
  /** A `double` representing the position's altitude in meters, relative to sea level. This value can be null if the implementation cannot provide the data. */
  altitude: null,
  /** A `double` representing the accuracy of the altitude expressed in meters. This value can be null. */
  altitudeAccuracy: null,
  /** A `double` representing the direction towards which the device is facing. This value, specified in degrees, indicates how far off from heading true north the device is. 0 degrees represents true north, and the direction is determined clockwise. If speed is 0, heading is `NaN`. If the device is unable to provide heading information, this value is `null`. */
  heading: null,
  /** A `double` representing the position's latitude in decimal degrees. */
  latitude: null,
  /** A `double` representing the position's longitude in decimal degrees. */
  longitude: null,
  /** A `double` representing the velocity of the device in meters per second. This value can be null. */
  speed: null,

  loading: false,
  error: null,
}

/**
 * Get users (device) location from `navigator.geolocation` once if user allows it.
 *
 * The hook return a state object and a function to invoke the position query as a tuple.
 * Invoke the `getLocation()` position query only from user action (event handler) or from `useEffect()` setup function.
 *
 * (to continuosly follow device location, implement a different hook using `geolocation.watchPosition()`)
 */
export default function useGeolocation(): [state: GeoLocationState, getLocation: (options?: PositionOptions) => void] {
  const [state, setState] = useState<GeoLocationState>(initState)

  /**
   * Get the current position of the user device.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition#options
   *
   * @param options - Optional options passed to `navigator.geolocation.getCurrentPosition()`
   */
  function getLocation(options?: PositionOptions) {
    setState(prev => {
      return { ...prev, loading: true }
    })

    if (!navigator || !("geolocation" in navigator)) {
      // this hook cannot function without `navigator.geolocation`. Set `error` to `true` to indicate "other error"
      setState({ ...initState, loading: false, error: true })
      return // exit
    }

    const successCallback: PositionCallback = ({ coords }) => {
      // object spread `{...coords}` doensn't work here
      // because `coords` objects fields are not its own enumerable properties but getters/setters
      setState({
        latitude: coords.latitude,
        longitude: coords.longitude,
        accuracy: coords.accuracy,
        altitude: coords.altitude,
        altitudeAccuracy: coords.altitudeAccuracy,
        heading: coords.heading,
        speed: coords.speed,
        loading: false,
        error: null,
      })
    }
    const errorCallback: PositionErrorCallback = error => {
      setState(s => ({
        ...initState,
        loading: false,
        error,
      }))
    }

    navigator.geolocation.getCurrentPosition(successCallback, errorCallback, options)
  }

  return [state, getLocation]
}
