import React, { ReactNode } from "react"
import { isBrowser } from "../../../utils"
import * as styles from "./MapMarker.module.scss"
import { MapMarkerType } from "./dealerUtils"

type MapMarkerProps = {
  isHoverable: boolean
  label?: ReactNode
  type: MapMarkerType
  /** google-map-react parametri */
  lat?: number
  /** google-map-react parametri */
  lng?: number
  onClick?: () => void
  children?: React.ReactNode
}

const MapMarker = (props: MapMarkerProps) => {
  //console.log('Rendering MapMarker', props)
  //const labels = { cluster: "+", retailer: "r", service: "s", store: "e", info: "i" }

  // TODO why pointerEvents instead of plain old :hover?
  const [visible, setVisible] = React.useState(false)
  if (!isBrowser) return null

  const enableSvgMarker = props.type == "retailer" || props.type == "service" || props.type == "store"

  return (
    <span
      onClick={() => props.onClick?.()}
      className={[styles.marker, styles[props.type], visible && props.isHoverable ? styles.hoverable : ""].join(" ")}
      onPointerEnter={() => setVisible(true)}
      onPointerLeave={() => setVisible(false)}>
      <div className={styles.circle}>{props.label || <>&nbsp;</>}</div>
      {enableSvgMarker && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
          <path d="M36,.5A26.12,26.12,0,0,0,9.9,26.59,27.37,27.37,0,0,0,15.32,42.5L36,71.5l20.69-29A27.34,27.34,0,0,0,62.1,26.59,26.09,26.09,0,0,0,36,.5ZM36,41A14.42,14.42,0,1,1,50.4,26.59,14.4,14.4,0,0,1,36,41Z" />
        </svg>
      )}

      {visible && <div className={styles.tooltip}>{props.children}</div>}
    </span>
  )
}

export default MapMarker
