import { HarviaDealer } from "../../../hooks/useDealers"

export type ServiceArea = {
  title: string | null
  id: string | null
}

export type MapMarkerType = "info" | "cluster" | "retailer" | "service" | "store"

type Marker = {
  type: MapMarkerType
  info: {
    title: string
    wwwAddress?: string | null
    phone?: string | null
    email?: string | null
    postalAddress?: {
      postOfficeBoxNumber?: string | null
      streetAddress?: string | null
      postalCode?: string | null
      addressLocality?: string | null
      addressRegion?: string | null
      addressCountry?: string | null
    }
  }
  serviceArea?: string
  dealerCount: number
  coordinates: { latitude: number; longitude: number }
}

/**
 * Calculate coordinates for map corners from markers.
 * Finds coordinates for north-east and south-west corners of a map that includes all
 * given markers.
 */
export function mapBounds(markers: Marker[]) {
  const bounds = { sw: { lat: 180, lng: 180 }, ne: { lat: -180, lng: -180 } }
  // let count = 0

  markers.forEach(marker => {
    if (!marker.coordinates) {
      return
    }
    // count++
    bounds.sw.lat = Math.min(bounds.sw.lat, marker.coordinates.latitude) - 0.0001
    bounds.sw.lng = Math.min(bounds.sw.lng, marker.coordinates.longitude) - 0.0001
    bounds.ne.lat = Math.max(bounds.ne.lat, marker.coordinates.latitude) + 0.0001
    bounds.ne.lng = Math.max(bounds.ne.lng, marker.coordinates.longitude) + 0.0001
  })

  return bounds
}

/**'
 * Convert from a DatoCMS "Contact dealer" records "Contact type" field (`contact_dealer.contact_type`) to
 * a machine friendly slug AND make sure we have a default value.
 */
function dealerTypeToMarkerType(dealerContactType: string | null): MapMarkerType {
  switch (dealerContactType) {
    case "Electrical supply store":
      return "store"
    case "Service shop":
      return "service"
    case "Retailer":
      return "retailer"
    default:
      // this is the important part, making sure we have sane default
      return "info"
  }
}

export function clusterize(dealers: Readonly<HarviaDealer[]>, clusters?: Readonly<ServiceArea[]>): Marker[] {
  // groupataan markkerit alueittain
  if (clusters !== undefined) {
    const markers: Marker[] = []
    // show clusters
    dealers.forEach(dealer => {
      if (!dealer.coordinates) {
        return
      }
      let clusterMarker = markers.find(marker => dealer.serviceAreas?.find(area => area?.id === marker.serviceArea))

      if (!clusterMarker) {
        const cluster = clusters.find(cluster => dealer.serviceAreas?.find(area => area?.id === cluster.id))
        if (!cluster?.title) {
          return
        }

        clusterMarker = {
          type: "cluster",
          info: {
            title: cluster.title,
          },
          serviceArea: cluster?.id || undefined,
          coordinates: { latitude: 0, longitude: 0 },
          dealerCount: 0,
        }
        markers.push(clusterMarker)
      }

      clusterMarker.dealerCount++
      clusterMarker.coordinates.latitude += dealer.coordinates.latitude ?? 0
      clusterMarker.coordinates.longitude += dealer.coordinates.longitude ?? 0
    })

    markers.forEach(marker => {
      marker.coordinates.latitude /= marker.dealerCount
      marker.coordinates.longitude /= marker.dealerCount
    })
    return markers
  }

  // show dealers
  return dealers
    .map(dealer => {
      if (!dealer.title || !dealer.coordinates) {
        return
      }

      return {
        type: dealerTypeToMarkerType(dealer.contactType),
        dealerCount: 1,
        coordinates: dealer.coordinates,
        info: {
          title: dealer.title,
          wwwAddress: dealer.wwwAddress,
          phone: dealer.phone,
          email: dealer.email,
          postalAddress: {
            postOfficeBoxNumber: dealer.postOfficeBoxNumber,
            streetAddress: dealer.streetAddress,
            postalCode: dealer.postalCode,
            addressLocality: dealer.addressLocality,
            addressRegion: dealer.addressRegion,
            addressCountry: dealer.addressCountry,
          },
        },
      }
    })
    .filter(Boolean) as Marker[]
}
