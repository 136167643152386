import { graphql, useStaticQuery } from "gatsby"

export type HarviaDealer = Queries.DatoContactDealerFragment

const useDealers = (areaIds?: string[]) => {
  const data = useStaticQuery<Queries.HarviaDealersQuery>(graphql`
    fragment DatoContactDealer on DatoCmsContactDealer {
      key: originalId
      addressCountry
      addressLocality
      addressRegion
      availabilityDetails
      callRates
      contactType
      coordinates {
        latitude
        longitude
      }
      description
      email
      faxNumber
      #locale
      logo {
        alt
        # "placeholder: BLURRED" adds fallback image as large data URI, "placeholder: DOMINANT_COLOR" adds only a "backgroundColor" prop with a hex code, e.g. "#39c151"
        image: gatsbyImageData(layout: FIXED, width: 164, placeholder: DOMINANT_COLOR)
      }
      phone
      phone2
      phone3
      postOfficeBoxNumber
      postalCode
      serviceAreas {
        ...DatoServiceArea
      }
      streetAddress
      title
      wwwAddress
      contactPerson {
        ...DatoContactPerson
      }
      contactPriority
    }
    query HarviaDealers {
      allDatoCmsContactDealer(locale: "en") {
        nodes {
          ...DatoContactDealer
        }
      }
    }
  `)

  const localeDealers = data.allDatoCmsContactDealer.nodes //.filter(dealer => dealer.locale === locale)

  if (areaIds)
    return localeDealers.filter(dealer => dealer.serviceAreas?.find(area => area && areaIds.includes(area.id || "")))

  return localeDealers
}

export default useDealers
