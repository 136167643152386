import React from "react"
import { IoIosArrowForward } from "react-icons/io"
import { IoLocationSharp } from "react-icons/io5"
import { HarviaDealer } from "../../hooks/useDealers"
import EmailLink from "../atoms/EmailLink"
import PhoneLink from "../atoms/PhoneLink"
import PostalAddress from "../atoms/PostalAddress"
import ContactPersonCard from "./ContactPersonCard"
import * as styles from "./DealerCard.module.scss"
import MapMarker from "./DealerMap/MapMarker"
import { MapMarkerType } from "./DealerMap/dealerUtils"

type DatoDealer = Omit<HarviaDealer, "logo"> & {
  logo?: React.ReactNode
}

type DealerCardProps = DatoDealer & {
  translatedDealerType?: string
  markerType: MapMarkerType
  distance?: number
  labelPrefixes: {
    abbrTelephone?: string
    abbrPoBox?: string
    linkShowOnMap?: string
    abbrEmail?: string
    abbrFax?: string
  }
}

const DealerCard = (props: DealerCardProps) => {
  // console.debug("Rendering DealerCard", props)

  const { labelPrefixes } = props

  //const DealerCard = (data: HarviaDealer & { distance?: number }) => {
  return (
    <div className={styles.card} itemScope itemType="https://schema.org/LocalBusiness">
      <span className={styles.distance}>
        {props.distance ? (
          <>
            <IoLocationSharp />
            {Math.round(props.distance / 1000)} km
          </>
        ) : (
          <>&nbsp;</>
        )}
      </span>

      <h3 className={styles.title} itemProp="name">
        {props.title}
      </h3>
      {props.description && <p itemProp="description">{props.description}</p>}

      <div className={styles.columns}>
        <div className={styles.address}>
          <PostalAddress labelPrefixPo={labelPrefixes.abbrPoBox} {...props} />
          <div className={styles.dealerInfo}>
            <div className={styles.mapMarkerContainer}>
              <MapMarker type={props.markerType} isHoverable={false} />
            </div>
            <div>
              {props.translatedDealerType && <p className={styles.dealerTypeLabel}>{props.translatedDealerType}</p>}
              {props.streetAddress && (
                <a
                  className={styles.mapLink}
                  href={`https://www.google.com/maps/search/${[
                    props.streetAddress,
                    props.addressLocality,
                    props.addressCountry,
                  ]
                    .filter(Boolean)
                    .join()}`}
                  target="_blank"
                  rel="nofollow noopener">
                  {labelPrefixes.linkShowOnMap} <IoIosArrowForward style={{ verticalAlign: "middle" }} />
                </a>
              )}
            </div>
          </div>
        </div>

        <div>
          {props.email && (
            <div>
              <EmailLink email={props.email} labelPrefix={labelPrefixes.abbrEmail} />
            </div>
          )}
          {props.phone && (
            <div>
              <PhoneLink phone={props.phone} labelPrefix={labelPrefixes.abbrTelephone} />
            </div>
          )}
          {props.phone2 && (
            <div>
              <PhoneLink phone={props.phone2} labelPrefix={labelPrefixes.abbrTelephone} />
            </div>
          )}
          {props.phone3 && (
            <div>
              <PhoneLink phone={props.phone3} labelPrefix={labelPrefixes.abbrTelephone} />
            </div>
          )}
          <div>
            {props.faxNumber && (
              <span itemProp="faxNumber">
                {labelPrefixes.abbrFax} {props.faxNumber}
              </span>
            )}
          </div>

          {props.wwwAddress && (
            <div>
              <a itemProp="url" href={props.wwwAddress} target="_blank" rel="noopener" className={styles.wwwAddress}>
                {props.wwwAddress}
              </a>
            </div>
          )}
        </div>
        <div itemScope itemType="https://schema.org/OrganizationRole">
          {props.contactPerson && (
            <div className={styles.contactPerson}>
              <ContactPersonCard
                //image={data.contactPerson.image?.fixed.src}
                name={`${props.contactPerson.firstname} ${props.contactPerson.lastname}`}
                role={props.contactPerson.roleRef?.role ?? undefined}
                phone={props.contactPerson.phone ?? undefined}
                email={props.contactPerson.email ?? undefined}
                details={props.contactPerson.details ?? undefined}
              />
            </div>
          )}
          {props.logo && <div>{props.logo}</div>}
        </div>
      </div>
    </div>
  )
}

export default DealerCard
