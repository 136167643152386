import GoogleMapReact, { fitBounds } from "google-map-react"
import React, { useCallback, useContext, useEffect, useRef } from "react"
import { PageContext } from "../../../context/PageContext"
import useDatoMicrocopy from "../../../hooks/useDatoMicrocopy"
import { HarviaDealer } from "../../../hooks/useDealers"
import EmailLink from "../../atoms/EmailLink"
import ExternalLink from "../../atoms/ExternalLink"
import PhoneLink from "../../atoms/PhoneLink"
import PostalAddress from "../../atoms/PostalAddress"
import * as styles from "./DealerMap.module.scss"
import MapMarker from "./MapMarker"
import { ServiceArea, clusterize, mapBounds } from "./dealerUtils"

type DealerMapProps = {
  dealers: HarviaDealer[]
  clusters: ServiceArea[] | false | undefined
  onMarkerClick?: (areaId: string) => void
}

const API_KEY = "AIzaSyDswI6aydw9Vw2XVPpf439GwfislgniyGM" // nitron oma, harvia projektin key

const DealerMap = (props: DealerMapProps) => {
  //console.log("Rendering DealerMap", props)
  const { locale } = useContext(PageContext)
  const mapRef = useRef<google.maps.Map | null>(null)
  const t = useDatoMicrocopy("contact", locale)

  const markers = clusterize(props.dealers, props.clusters || undefined)

  const clusterClickHandler = (areaId: string) => {
    if (!props.onMarkerClick) {
      return
    }
    props.onMarkerClick(areaId)
  }

  const calcBounds = useCallback(
    (mapZoom?: number, bounds?: GoogleMapReact.Bounds) => {
      // console.debug("Calculating map bounds")
      const map = mapRef.current
      if (!map) {
        return
      }

      const size = {
        width: 500, // Map width in pixels
        height: 500, // Map height in pixels
      }

      const markerBound = mapBounds(markers)
      const { center, zoom } = fitBounds(bounds || markerBound, size)

      map.setZoom(Math.min(mapZoom || zoom, 14))
      map.setCenter(center)
    },
    [markers]
  )

  useEffect(() => {
    calcBounds()
  }, [calcBounds, markers])

  return (
    <>
      <div style={{ height: 500 }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: API_KEY }}
          yesIWantToUseGoogleMapApiInternals
          defaultCenter={{ lat: 65, lng: 25 }}
          defaultZoom={5}
          onGoogleApiLoaded={({ map }) => {
            mapRef.current = map
          }}
          onChange={({ zoom, bounds }) => {
            calcBounds(zoom, bounds)
          }}
          // center={center}
          // zoom={mapZoom}
          options={{ streetViewControl: true, minZoom: 2 }}>
          {markers.map(marker => {
            const { info } = marker
            return (
              <MapMarker
                key={`${marker.type}-${info.title}-${marker.dealerCount || 0}`}
                type={marker.type}
                lat={marker.coordinates?.latitude}
                lng={marker.coordinates?.longitude}
                label={marker.type == "cluster" ? marker.dealerCount : null}
                onClick={marker.type == "cluster" ? () => clusterClickHandler(marker.serviceArea as string) : undefined}
                isHoverable={true}>
                <>
                  <h3>{info.title}</h3>
                  {info.wwwAddress && (
                    <div>
                      <ExternalLink itemProp="url" href={info.wwwAddress}>
                        {info.wwwAddress}
                      </ExternalLink>
                    </div>
                  )}
                  {info.phone && <PhoneLink phone={info.phone} labelPrefix={t("abbrTelephone", "Tel.")} />}
                  {info.email && <EmailLink email={info.email} labelPrefix={t("abbrEmail", "Email")} />}
                  <PostalAddress labelPrefixPo={t("abbrPoBox", "P.O.Box")} {...info.postalAddress} />
                </>
              </MapMarker>
            )
          })}
        </GoogleMapReact>
      </div>
      <div className={styles.glossary}>
        <div className={styles.glossaryItem}>
          <MapMarker type="store" isHoverable={false} />
          <div className={styles.glossaryLabel}>{t("dealerTypeElectricalSupplyStore", "Electrical supply store")}</div>
        </div>
        <div className={styles.glossaryItem}>
          <MapMarker type="service" isHoverable={false} />
          <div className={styles.glossaryLabel}>{t("dealerTypeServiceShop", "Service shop")}</div>
        </div>
        <div className={styles.glossaryItem}>
          <MapMarker type="retailer" isHoverable={false} />
          <div className={styles.glossaryLabel}>{t("dealerTypeRetailer", "Retailer")}</div>
        </div>
      </div>
    </>
  )
}

export default DealerMap
