// extracted by mini-css-extract-plugin
export var address = "DealerCard-module--address--66a48";
export var card = "DealerCard-module--card--4b771";
export var columns = "DealerCard-module--columns--69e94";
export var contactPerson = "DealerCard-module--contactPerson--0714f";
export var dealerInfo = "DealerCard-module--dealerInfo--3ffd6";
export var dealerTypeLabel = "DealerCard-module--dealerTypeLabel--07e31";
export var distance = "DealerCard-module--distance--90f82";
export var mapLink = "DealerCard-module--mapLink--7bbf3";
export var mapMarkerContainer = "DealerCard-module--mapMarkerContainer--13833";
export var title = "DealerCard-module--title--a7cce";
export var wwwAddress = "DealerCard-module--wwwAddress--0a494";